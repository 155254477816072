<template>
  <div>
        <div class="row">
            <div class="col-md-4" style="display: flex; flex-direction: column;">
                <div class="card card-outline card-primary" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Rechnung</h3>
                    </div>

                    <div class="card-body">
                        <span v-if="order.billing.company != null">
                            {{ order.billing.company }}<br>
                            <span v-if="order.billing.department != null">
                                {{ order.billing.department }}<br>
                            </span>    
                        </span>
                        {{ order.billing.first_name }} {{ order.billing.last_name }}<br>
                        {{ order.billing.street }} {{ order.billing.house_number }}<br>
                        {{ order.billing.zipcode }} {{  order.billing.city }}
                    </div>

                </div>
            </div>
            <div class="col-md-4" style="display: flex; flex-direction: column;">
                <div class="card card-outline card-primary" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Versand</h3>
                    </div>

                    <div class="card-body">
                        <span v-if="order.shipping.company != null">
                            {{ order.shipping.company }}<br>
                            <span v-if="order.shipping.department != null">
                                {{ order.shipping.department }}<br>
                            </span>    
                        </span>
                        {{ order.shipping.first_name }} {{ order.shipping.last_name }}<br>
                        {{ order.shipping.street }} {{ order.shipping.house_number }}<br>
                        {{ order.shipping.zipcode }} {{  order.shipping.city }}
                    </div>

                </div>
            </div>
            <div class="col-md-4" style="display: flex; flex-direction: column;">
                <div class="card card-outline card-primary" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Zahlungsarten</h3>
                    </div>

                    <div class="card-body">
                        {{ order.payment.description }}
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Bestellung-Details</h3>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Shop:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        {{ order.company.name }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Bestellzeit:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        {{ order.orderdate }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Bestellnummer:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        {{ order.ordernumber }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Gesamtbetrag:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        {{ order.invoice_amount | toCurrency }}
                                    </div>
                                </div>

                                <div class="mb-1 row text-danger" v-if="order.total_stornos != 0">
                                    <div class="col-md-3">
                                        <strong>Stornos:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        {{ order.total_stornos | toCurrency }}
                                    </div>
                                </div>

                                <div class="mb-1 row text-primary">
                                    <div class="col-md-3">
                                        <strong>Zahlbetrag:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        {{ order.invoice_amount + order.total_stornos | toCurrency }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Gewählte Versandart:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        {{ order.dispatch.name }}
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Email:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        <a :href="'mailto:' + order.customer.email">{{ order.customer.email }}</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Wunschlieferdatum:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        <span v-if="order.wishdate == null">-- Nicht vorhanden --</span>
                                        <span v-else><strong>{{ order.wishdate | formatDate }}</strong></span>
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Abstellhinweis:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        <span v-if="order.delivery_note == null">-- Nicht vorhanden --</span>
                                        <span v-else><strong>{{ order.delivery_note }}</strong></span>
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Alternativartikel:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        <span v-if="order.alternative==0 || order.alternative == null" style="color: red"><strong>KEINE ZUSTIMMUNG</strong></span>
                                        <span v-else style="color: green"><strong>ERLAUBT</strong></span>
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Abholfiliale:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        -- Nicht vorhanden --
                                    </div>
                                </div>

                                <div class="mb-1 row">
                                    <div class="col-md-3">
                                        <strong>Abholzeitpunkt:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        -- Nicht vorhanden --
                                    </div>
                                </div>

                                <div class="mt-3 mb-1 row align-items-center">
                                    <div class="col-md-3">
                                        <strong>Kunde:</strong>
                                    </div>
                                    <div class="col-md-9">
                                        <router-link class="btn btn-primary btn-sm" :to="{name: 'customers.show', params: {id: order.customer.id}}" v-if="$auth.check('customers.show')"><i class="fas fa-user"></i> Kunde öffnen</router-link>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success mt-2">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchShowCustomer" v-model="order.show_customer" disabled>
                                        <label class="custom-control-label" for="customSwitchShowCustomer">Bestellung im Kundenkonto anzeigen</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Bestellung bearbeiten</h3>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Bezahlt am:</label>
                                    <div class="col-md-8">
                                        <input type="date" class="form-control form-control-sm" v-model="order.cleared" disabled />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Trackingcode:</label>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control form-control-sm" disabled />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Versandkosten:</label>
                                    <div class="col-md-8">
                                        <input type="number" class="form-control form-control-sm" v-model="order.invoice_shipping" disabled />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Versandkosten Steuersatz:</label>
                                    <div class="col-md-8">
                                        <input type="number" class="form-control form-control-sm" v-model="order.invoice_shipping_tax_rate" disabled />
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Bestellstatus:</label>
                                    <div class="col-md-8">
                                        <select class="form-control form-control-sm" v-model="order.order_status" disabled>
                                            <option v-for="state in orderStates" :key="state.id" :value="state.id">{{ state.description }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="" class="col-md-2 col-form-label">Zahlungsstatus:</label>
                                    <div class="col-md-8">
                                        <select class="form-control form-control-sm" v-model="order.billing_status" disabled>
                                            <option v-for="state in billingStates" :key="state.id" :value="state.id">{{ state.description }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>	
export default {
    name: 'OrderDetailsOverview',
    props: [
        'order'
    ],
    data(){
        return  {
            orderStates: [],
            billingStates: [],
            oldOrderState: null,
            oldBillingState: null,
            test: '',
            newState: {
                template:{
                    subject: '',
                }              
            },
            options: {
                replaceable: {
                    '{first_name}': this.order.customer.first_name,
                    '{company_name}': this.order.company.name,
                    '{tracking_code}': '',
                    '{ordernumber}': this.order.ordernumber,
                    '{orderdate}': this.order.orderdate,
                    '{invoicenumber}': this.order.invoice.number,      
                }
            }
        }
    },

    methods:{
        sendNotification(){
            this.$Progress.start();
            this.axios
                .post("/mail/send", {
                    'email': this.order.customer.email,
                    'subject': this.newState.template.subject,
                    'content': this.test,
                    'company_id': this.order.company.id,
                })
                .then(() => {
                    this.$Progress.finish();
                    this.$swal({
                        icon: "success",
                        title: "E-Mail gesendet",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
        
        saveOrder(){
            this.$Progress.start();
            this.axios
                .put("/orders/" + this.order.id, {
                    'trackingcode': this.order.trackingcode,
                    'cleared': this.order.cleared,
                    'invoice_shipping': this.order.invoice_shipping,
                    'invoice_shipping_tax_rate': this.order.invoice_shipping_tax_rate,
                    'order_status': this.order.order_status,
                    'billing_status': this.order.billing_status,
                })
                .then(() => {
                    // this.billingStates = response.data.data;
                    this.$swal({
                        icon: "success",
                        title: "Bestelldetails wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.$parent.getOrder();
                    this.$Progress.finish();

                    if(this.oldOrderState != this.order.order_status)
                    {
                        //Status hat sich geändert. Prüfe nun ob eine Mail vesendet werden muss
                        this.newState = this.orderStates.find( data => data.id === this.order.order_status );

                        if(this.newState.template == null)
                        {
                            this.newState.template = {
                                'subject': '',
                            }
                        }

                        if(this.newState.mail == 1)
                        {
                            const input = this.replaceVariables(this.newState.template.content || '')
                            this.test = input;
                            //Es muss eine Mail gesendet werden
                            this.$bvModal.show("mailModal");
                        }

                        this.oldOrderState = this.order.order_status;
                    }

                    if(this.oldBillingState != this.order.billing_status)
                    {
                        //Status hat sich geändert. Prüfe nun ob eine Mail vesendet werden muss
                        this.newState = this.billingStates.find( data => data.id === this.order.billing_status );

                        if(this.newState.template == null)
                        {
                            this.newState.template = {
                                'subject': '',
                            }
                        }

                        if(this.newState.mail == 1)
                        {
                            const input = this.replaceVariables(this.newState.template.content || '')
                            this.test = input;
                            //Es muss eine Mail gesendet werden
                            this.$bvModal.show("mailModal");
                        }

                        this.oldBillingState = this.order.billing_status;
                    }

                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        replaceVariables(input) {
			let lookup = this.options.replaceable || {}
			let updated = input
			Object.keys(lookup).forEach((key) => {
				updated = updated.replace(new RegExp(key, 'g'), lookup[key])
			})
			return updated
		},

        getOrderStates(){
            this.$Progress.start();
            this.axios
                .get("/states/order")
                .then((response) => {
                    this.orderStates = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getBillingStates(){
            this.$Progress.start();
            this.axios
                .get("/states/billing")
                .then((response) => {
                    this.billingStates = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created() {
        this.getOrderStates();
        this.getBillingStates();
        this.oldOrderState = this.order.order_status;
        this.oldBillingState = this.order.billing_status;
    }

}
</script>

<style>

</style>