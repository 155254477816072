<template>
    <b-container fluid>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary card-tabs">
              <div class="card-header p-0 pt-1">
                <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="custom-tabs-one-overview-tab" data-toggle="pill" href="#custom-tabs-one-overview" role="tab" aria-controls="custom-tabs-one-overview" aria-selected="true">Übersicht</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="custom-tabs-one-details-tab" data-toggle="pill" href="#custom-tabs-one-details" role="tab" aria-controls="custom-tabs-one-details" aria-selected="false">Details</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="custom-tabs-one-communication-tab" data-toggle="pill" href="#custom-tabs-one-communication" role="tab" aria-controls="custom-tabs-one-communication" aria-selected="false">Kommunikation</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="custom-tabs-one-items-tab" data-toggle="pill" href="#custom-tabs-one-items" role="tab" aria-controls="custom-tabs-one-items" aria-selected="false">Positionen</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="custom-tabs-one-documents-tab" data-toggle="pill" href="#custom-tabs-one-documents" role="tab" aria-controls="custom-tabs-one-documents" aria-selected="false">Dokumente</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="custom-tabs-one-shipping-tab" data-toggle="pill" href="#custom-tabs-one-shipping" role="tab" aria-controls="custom-tabs-one-shipping" aria-selected="false">Versand</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content" id="custom-tabs-one-tabContent">
                  <div class="tab-pane fade active show" id="custom-tabs-one-overview" role="tabpanel" aria-labelledby="custom-tabs-one-overview-tab">
                      <Overview :order="order" v-if="notEmptyObject(order)" />
                  </div>
                  <div class="tab-pane fade" id="custom-tabs-one-details" role="tabpanel" aria-labelledby="custom-tabs-one-details-tab">
                      <Details :order="order" v-if="notEmptyObject(order)" />
                  </div>
                  <div class="tab-pane fade" id="custom-tabs-one-communication" role="tabpanel" aria-labelledby="custom-tabs-one-communication-tab">
                      <Communication :order="order" v-if="notEmptyObject(order)" />
                  </div>
                  <div class="tab-pane fade" id="custom-tabs-one-items" role="tabpanel" aria-labelledby="custom-tabs-one-items-tab">
                      <Items :order="order" v-if="notEmptyObject(order)" />
                  </div>
                  <div class="tab-pane fade" id="custom-tabs-one-documents" role="tabpanel" aria-labelledby="custom-tabs-one-documents-tab">
                      <Documents :order="order" v-if="notEmptyObject(order)" />
                  </div>
                  <div class="tab-pane fade" id="custom-tabs-one-shipping" role="tabpanel" aria-labelledby="custom-tabs-one-shipping-tab">
                      <Shipping :order="order" v-if="notEmptyObject(order)" />
                  </div>
                </div>
              </div>
              <!-- /.card -->
            </div>
        </div>
      </div>
    </b-container>
</template>

<script>
import Overview from './components/show/Overview';
import Details from './components/show/Details';
import Communication from './components/show/Communication';
import Items from './components/show/Items';
import Documents from './components/show/Documents';
import Shipping from './components/show/Shipping';

export default {
  title: "Bestellung bearbeiten",
  name: "OrdersDetails",
  components: {
      Overview,
      Details,
      Items,
      Documents,
      Shipping,
      Communication,
  },

  data() {
    return {
      order: {},
    }
  },

  methods:{
    getOrder(){
      this.$Progress.start();
      this.axios
          .get("/orders/" + this.$route.params.id)
          .then((response) => {
              this.order = response.data.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    }
  },

  created() {
    this.getOrder();
  }

}
</script>
